<!--
 * ======================================
 * 说明： 系统日志
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/5/14 22:50
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <Main
      class="h-full"
      ref="main"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      api="/log/list"
    >
    </Main>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import { columns } from "./config";
export default {
  name: "",
  components: { Main },
  props: {},
  data() {
    return {
      columns,
      searchForm: {},
    };
  },
  computed: {},
  created() {},
  async mounted() {
    await this.onFind();
  },
  methods: {
    onParams() {
      return this.searchForm;
    },

    /**
     *  @actions:  查询列表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 13:26
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onFind() {
      await this.$refs["main"].onFind();
    },
  },
};
</script>
<style lang="scss" scoped></style>
