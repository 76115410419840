/**
 * ======================================
 * 说明： 评价指标管理配置
 * 作者： Silence
 * 文件： config.js
 * 日期： 2023/5/9 13:13
 * ======================================
 */
export const columns = [
  {
    field: "create_username",
    label: "操作人",
    align: "left",
    width: "120",
  },
  {
    field: "create_role",
    label: "操作人角色",
    align: "left",
    width: "120",
  },
  {
    field: "module",
    label: "模块",
    align: "center",
    width: "120,",
  },
  {
    field: "content",
    label: "内容",
    align: "left",
    width: "auto,",
  },
  {
    field: "created_time",
    label: "操作时间",
    align: "left",
    width: "160",
  },
  // {
  //   field: "updated_time",
  //   label: "更新时间",
  //   align: "left",
  //   width: "160",
  // },
  // {
  //   field: "deleted_time",
  //   label: "删除时间",
  //   align: "left",
  //   width: "160",
  // },
];

export const searchOptions = [
  {
    type: "input",
    field: "name",
    label: "指标名称",
    placeholder: "按指标名称查询",
  },
  {
    type: "number",
    field: "score",
    label: "分值",
    placeholder: "按分值查询",
  },
];
